import type { ComponentProps } from 'react';

import styles from './ScrollButton.module.scss';

import analytics from '#utils/analytics';
import { scrollToInput } from '#utils/scroll';

interface Props extends ComponentProps<'button'> {
  text: string;
  styleType: string;
  eventAction?: string;
  customStyle?: string | string[];
}

const ScrollButton = ({ text, styleType, customStyle, eventAction, ...commonProps }: Props) => {
  const handleClick = () => {
    if (eventAction) analytics.event({ action: eventAction });

    scrollToInput();
  };
  return (
    <button
      className={`${styles.button} ${styleType && styles[styleType]} ${customStyle && (Array.isArray(customStyle) ? customStyle.map((cls) => styles?.[cls] || cls).join(' ') : styles[customStyle])}`}
      onClick={handleClick}
      {...commonProps}
    >
      {text}
    </button>
  );
};

export default ScrollButton;
